import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, type StripeElementsOptions } from '@stripe/stripe-js';
import type { PropsWithChildren } from 'react';

type Props = {
  options?: StripeElementsOptions;
};

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_TOKEN);

export const StripeElementProvider = (props: PropsWithChildren<Props>) => {
  const options: StripeElementsOptions = {
    ...props.options,
  };
  return (
    <Elements options={options} stripe={stripePromise}>
      {props.children}
    </Elements>
  );
};
