import {
  PayPalScriptProvider,
  type ReactPayPalScriptOptions,
} from '@paypal/react-paypal-js';
import type { ReactNode } from 'react';

type Props = {
  options?: Omit<ReactPayPalScriptOptions, 'client-id'>;
  children: ReactNode;
};

export const PaypalProvider = ({ options, children }: Props) => (
  <PayPalScriptProvider
    options={{
      ...options,
      'client-id': import.meta.env.VITE_PAYPAL_CLIENT_ID,
    }}
  >
    {children}
  </PayPalScriptProvider>
);
