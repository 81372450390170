export const MAIN_APP_DASHBOARD_URL = `${
  import.meta.env.VITE_MAIN_APP_URL
}/dashboard#mySettings`;

export const MAIN_APP_MIGRATION_URL = `${
  import.meta.env.VITE_MAIN_APP_URL
}/migration?ref=migrationFromCheckout`;

export const STRIPE_MANAGE_SUBSCRIPTION_URL = `${
  import.meta.env.VITE_MAIN_APP_URL
}/inc/dml/userDML.php?action=redirect-to-stripe-customer-portal`;
