/* TODO: figure out display name */
/* eslint-disable react/display-name */
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, type StripeElementsOptions } from '@stripe/stripe-js';

import { useUser } from '@/features/auth/hooks';
import { PaypalProvider } from '@/shared/components';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_TOKEN);

export const withPaymentProviders =
  (Component: React.ComponentType, paypalIntent = 'subscription') =>
  (props: any) => {
    const user = useUser();

    const options: StripeElementsOptions = {
      ...props.options,
    };

    return (
      <Elements options={options} stripe={stripePromise}>
        <PaypalProvider
          options={{
            currency: user.currency.toUpperCase(),
            intent: paypalIntent,
            vault: true,
          }}
        >
          <Component {...props} />
        </PaypalProvider>
      </Elements>
    );
  };
